/* Questionnaire.css */

.questionnaire-container {
	max-width: 330px;
	margin: 0 auto;
	background-color: #f1f1f1;
	border-radius: 25px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	box-shadow: 0px -8px 20px 1px rgba(0, 0, 0, 0.2);
    z-index: 999;
}
.rounded-input {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 25px;
	box-sizing: border-box;
	box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.rounded-input.error-border:not(:focus) {
	border-color: red;
}

.rounded-input:focus {
	border-color: #ccc;
}
.error-message {
	color: red;
	font-family: "Nunito", sans-serif;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
	font-family: "Nunito", sans-serif;
	width: 100%;
	padding: 10px;
	margin-bottom: 15px;
	border: 1px solid #ccc;
	border-radius: 25px;
	box-sizing: border-box;
	box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.2); /* Add drop shadow */
}

.datetime-container {
	display: flex;
	gap: 10px;
}
form {
	display: flex;
	flex-direction: column;
}

.questionnaire-container button {
	align-self: flex-end;
}

.questionnaire-container button:hover {
	background-color: rgb(212, 89, 48);
}

.paddedTop {
	padding-top: 10px;
}
.paddedBot {
	padding-bottom: 10px;
}

.headerImg {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 25px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	font-family: "DynaPuff";
	text-transform: uppercase;
	font-size: 1.7rem;
	background-image: url("../../img/formHeader.png");
	color: #fff;
	width: 367px;
	max-width: 407px;
	height: 78px;
	margin-bottom: 25px;
}
.custom-input {
	position: relative;
}

.custom-input input {
	padding-right: 30px; /* Adjust as needed based on the icon size */
}

.custom-input img {
	position: absolute;
	top: 35%;
	right: 10px;
	transform: translateY(-50%);
	width: 20px; /* Adjust the width of the icon as needed */
	height: auto;
}

.react-datepicker-popper {
	z-index: 9999; /* Adjust the z-index value as needed */
}

@media (max-width: 450px) {
	.questionnaire-container {
		transform: scale(0.8); /* Adjust the scale value as needed */
	}
}

@media (max-width: 1000px) {
	.questionnaire-container {
		box-shadow: none;
	}
}
