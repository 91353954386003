.hotelContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	width: 100%;
}
.hotelHeader {
	width: 100%;
	height: 646px;
	background-image: url("../../img/heroHotel.png");
	background-size: cover;
}

.hotelHeaderText {
	font-size: 3rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(255, 199, 38);
	padding-top: 10%;
	padding-left: 15%;
	text-transform: uppercase;
}

.hotelTogether {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 70%;
	height: 100%;
	padding: 0 15%;
}
.hotelLeft {
	position: relative;
	top: -150px;
	display: flex;
	flex-direction: column;
	width: 65%;
	height: 100%;
	text-align: left;
}
.hotelRight {
	position: relative;
	top: -250px;
	display: flex;
	flex-direction: column;
	width: 30%;
	height: 100%;
}
.textHeader {
	font-size: 2rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(0, 166, 199);
	text-transform: uppercase;
	margin: 5% 0%;
}
.headerMini {
	font-size: 1.2rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(0, 166, 199);
	text-transform: uppercase;
	margin: 5% 0%;
}
.textNormal {
	font-size: 1rem;
	font-family: "Nunito", sans-serif;
	color: rgb(104, 104, 104);
}

.textItem {
	display: flex;
	flex-direction: row;
	font-size: 1rem;
	font-family: "Nunito", sans-serif;
	color: rgb(104, 104, 104);
	margin-top: 0%;
	margin-bottom: 4%;
	align-items: center;
	justify-content: center;
}
.textItem img {
	width: 8%;
	height: 8%;
}
.itemTextTogether {
	display: flex;
	flex-direction: column;
	margin-left: 3%;
}
.itemHeader {
	font-size: 1.2rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(0, 166, 199);
	text-transform: uppercase;
}

@media (max-width: 1000px) {
	.hotelHeader {
		height: 300px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.hotelHeaderText {
		font-size: 2rem;
		padding: 0;
		text-shadow: -1px 1px 9px rgba(0, 0, 0, 0.75);
	}
	.hotelContainer .textHeader {
		font-size: 1.8rem;
		justify-content: center;
	}

	.hotelTogether {
		flex-direction: column;
		padding-left: 5%;
		padding-right: 5%;
		width: 90%;
	}
	.hotelLeft {
		width: 100%;
		text-align: center;
		padding-bottom: 15%;
		top: -50px;
	}

	.hotelRight {
		padding-top: 5%;
		width: 100%;
		top: -0px;
	}
	.hotelContainer .headerMini {
		font-size: 1.5rem;
	}
}
