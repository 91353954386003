.galeryContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: 20px;
	width: 387px;
	height: 339px;
	margin: 0 auto;
	background-image: url("../../img/galeryImg.png");
	margin-bottom: 20px;
    margin-top: 75px;
    z-index: 99;
}

.headerImgGalery {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 25px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	font-family: "DynaPuff";
	text-transform: uppercase;
	font-size: 1.7rem;
	background-image: url("../../img/galeryHeaderImg.png");
	color: #fff;
	width: 367px;
	max-width: 407px;
	height: 78px;
	margin-bottom: 25px;
}

.galeryLink button {
	margin-top: 185px;
	margin-left: 190px;
}

.galeryContainer button:hover {
	background-color: rgb(212, 89, 48);
}

@media (max-width: 450px) {
	.galeryContainer {
		transform: scale(0.6); /* Adjust the scale value as needed */
	}
}
