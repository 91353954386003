.galerijaContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	width: 100%;
}
.galerijaHeader {
	width: 100%;
	height: 646px;
	background-image: url("../../img/galeryHero.png");
	background-size: cover;
}

.galerijaHeaderText {
	font-size: 3rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(255, 199, 38);
	padding-top: 10%;
	padding-left: 15%;
	text-transform: uppercase;
}

.galerijaTogether {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 70%;
	height: 100%;
	padding: 0 15%;
}
.galerijaLeft {
	position: relative;
	top: -180px;
	display: flex;
	flex-direction: column;
	width: 65%;
	height: 100%;
	text-align: left;
}
.galerijaRight {
	position: relative;
	top: -270px;
	display: flex;
	flex-direction: column;
	width: 30%;
	height: 100%;
}

.galerijaLeft .headText {
	font-size: 2rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(0, 166, 199);
	text-transform: uppercase;
	margin: 5% 0%;
}
.galerijaLeft .descText {
	font-size: 1rem;
	font-family: "Nunito", sans-serif;
	color: rgb(104, 104, 104);
	margin-bottom: 5%;
}

.galerijaImages {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	padding-bottom: 10%;
}
.galerijaImages img {
	width: 30%;
	height: 100%;
	transition: transform 0.3s ease; /* Add smooth transition on hover */
	cursor: pointer;
}

.galerijaImages img:hover {
	transform: scale(1.1); /* Adjust the scale factor for zoom level */
}

@media (max-width: 1000px) {
	.galerijaHeader {
		height: 400px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.galerijaHeaderText {
		font-size: 2.5rem;
		padding: 0;
		text-shadow: -1px 1px 9px rgba(0, 0, 0, 0.75);
	}

	.galerijaTogether {
		flex-direction: column;
		padding-left: 5%;
		padding-right: 5%;
		width: 90%;
	}
	.galerijaLeft {
		width: 100%;
		text-align: center;
		padding-bottom: 15%;
		top: -100px;
	}
	.galerijaLeft .headText {
		font-size: 1.8rem;
		justify-content: center;
	}

	.galerijaRight {
		padding-top: 5%;
		width: 100%;
		top: -100px;
	}
	.galerijaImages {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding-bottom: 5%;
	}

	.galerijaImages img {
		width: 70%;
		height: 100%;
		transition: transform 0.3s ease; /* Add smooth transition on hover */
		cursor: pointer;
		padding-bottom: 5%;
	}
}
