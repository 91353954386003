/* Navbar.css */

.navbar-container {
	display: flex;
	flex-direction: column;
}

.navbar {
	display: flex;
	height: 100px;
	padding: 0 15%;
	background-color: rgb(0, 166, 199);
	transition: all 0.3s; /* Add smooth transition for width changes */
}

.nav-item {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color: #fff;
	font-family: "DynaPuff";
	transition: background-color 0.5s;
}

.nav-item:first-child img {
	width: 95%;
}

.nav-icon {
	margin-left: 10px;
	width: 15%;
	max-width: 15%;
}

.selected {
	background-color: rgb(0, 145, 174);
}

.selected:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.nav-item:not(:first-child):hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.blue-line {
	height: 20px;
	background-color: rgb(0, 145, 174);
	margin: 0;
}

.yellow-line {
	height: 20px;
	background-color: rgb(255, 199, 38);
	margin: 0;
}

.sticky .navbar-container {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
}

.sticky .navbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
    height: 70px;
	box-sizing: border-box; /* Add this to include padding in the width calculation */
	padding: 0 15%; /* Keep the same padding */
	background-color: rgb(0, 166, 199);
	border-bottom: 5px solid rgba(255, 201, 38, 0.89);
}

.sticky .nav-item:first-child img {
	width: 100%; /* Adjusted to maintain the same width */
}

.sticky .blue-line,
.sticky .yellow-line {
	display: none;
}
