.mainContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	width: 100%;
}
.mainHeader {
	width: 100%;
	height: 800px;
	background-image: url("../../img/heroMain.png");
	background-size: cover;
}

.mainHeaderText {
	font-size: 3rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(255, 199, 38);
	padding-top: 10%;
	padding-left: 15%;
	text-transform: uppercase;
}
.headerSpan {
	color: rgb(255, 199, 38);
}
.heroButtons {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding-top: 2%;
	width: 85%;
	height: 10%;
	padding-left: 15%;
}
.heroButtons button {
	text-transform: uppercase;
	font-size: 0.8rem;
}

.heroButtonTwo button {
	background-color: rgb(0, 166, 199);
	margin-left: 25%;
}
.heroButtonTwo button:hover {
	background-color: rgb(3, 149, 179);
}
.mainTogether {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	width: 70%;
	height: 100%;
	padding: 0 15%;
}
.mainLeft {
	position: relative;
	top: -120px;
	display: flex;
	flex-direction: column;
	width: 65%;
	height: 100%;
	text-align: left;
}
.mainRight {
	position: relative;
	top: -250px;
	display: flex;
	flex-direction: column;
	width: 30%;
	height: 100%;
}
.textHeaderM {
	font-size: 2rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(0, 166, 199);
	text-transform: uppercase;
	margin: 0% 0%;
}
.textNormalM {
	font-size: 1rem;
	font-family: "Nunito", sans-serif;
	color: rgb(104, 104, 104);
	margin: 2% 0%;
}

.mainLeft {
	display: flex;
	flex-direction: row;
}
.leftBig {
	width: 20%;
	height: 100%;
}
.fourboxes {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.topBoxes {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.botBoxes {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.boxItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 45%;
	padding: 2%;
	padding-left: 0%;
}
.boxText {
	font-size: 0.9rem;
	font-family: "Nunito", sans-serif;
	color: rgb(104, 104, 104);
	margin-left: 5%;
	text-transform: uppercase;
}

.textNormalM .contact {
	text-decoration: none;
}

.linkSpanY {
	color: rgb(255, 199, 38);
}
.linkSpanB {
	color: rgb(0, 166, 199);
}

.buttonsHBot {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding-top: 2%;
	width: 100%;
	height: 10%;
}
.buttonsHBot button {
	text-transform: uppercase;
	font-size: 0.8rem;
}

.botButtonOne button {
	background-color: rgb(255, 199, 38);
}
.botButtonOne button:hover {
	background-color: rgb(233, 184, 36);
}

.botButtonTwo button {
	background-color: rgb(0, 166, 199);
	margin-left: 25%;
}
.botButtonTwo button:hover {
	background-color: rgb(3, 149, 179);
}

.mainCenter {
	width: 100%;
	height: 670px;
	background-image: url("../../img/mainPoz2.png");
	background-size: cover;
	display: flex;
	flex-direction: column;
	padding-top: 2%;
}
.centerText {
	text-align: center;
}
.galeryImages {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 65%;
	height: 50%;
	padding-left: 25%;
}
.arrowImage {
	cursor: pointer;
	transition: transform 0.3s ease;
}
.arrowImage:hover {
	transform: scale(1.1);
}

.galeryRotatingImages {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%; /* Take the full width */
}

.imgElement {
	width: 30%;
	height: 100%;
    max-height: 150px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.imgElement img {
	width: 90%;
	height: 90%;
    max-height: 300px;
	transition: transform 0.3s ease;
    border-radius: 20px;
}
.imgElement img:hover {
	transform: scale(1.1);
}
.imgElement:nth-child(2) {
	padding-top: 15%;
}

.mainBottom {
	width: 70%;
	height: 100%;
	display: flex;
	flex-direction: row;
}
.imgSapa {
	padding-bottom: 5%;
}

.mainBottomL {
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 5%;
	padding-left: 5%;
}
.mainBottomR {
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 5%;
	padding-left: 5%;
}

.teamImgs {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-top: 5%;
}
.teamImgs img {
	width: 80%;
	height: 80%;
}

.teamNameText {
	font-size: 1.5rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(255, 199, 38);
	text-shadow: 1px 1px 2px rgba(218, 171, 30, 1);
	text-transform: uppercase;
	margin: 0% 0%;
	text-align: center;
}

.lowerButtons {
	padding-top: 10%;
}

.lowerTwo {
	padding-left: 5%;
	padding-bottom: 5%;
}
.imgVozacBaki img {
	width: 100%;
	height: 100%;
	padding-top: 25%;
}
.textVozacBaki {
	font-size: 1.2rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(0, 166, 199);
	text-shadow: 1px 1px 2px rgb(2, 175, 209);
	text-transform: uppercase;
	padding-left: 8%;
}

@media (max-width: 1000px) {
	.mainHeader {
		height: 400px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.mainHeaderText {
		font-size: 2rem;
		padding: 0;
		text-shadow: -1px 1px 9px rgba(0, 0, 0, 0.75);
	}
	.heroButtons {
		align-items: center;
		justify-content: center;
		width: 100%;
		padding-left: 0;
	}

	.mainTogether {
		flex-direction: column;
		padding-left: 5%;
		padding-right: 5%;
		width: 90%;
	}
	.leftBig {
		display: none;
	}
	.mainLeft {
		width: 100%;
		text-align: center;
		padding-bottom: 15%;
		top: -50px;
	}
	.fourboxes {
		align-items: center;
		justify-content: center;
	}
	.boxItem:nth-child(2) {
		align-items: center;
		justify-content: flex-start;
	}
	.mainRight {
		padding-top: 5%;
		width: 100%;
		top: 0px;
	}
	.buttonsHBot {
		align-items: center;
		justify-content: center;
	}
	.mainCenter {
		height: 100%;
		padding-top: 0;
	}

	.galeryRotatingImages {
		justify-content: center;
	}
	.galeryImages {
		width: 80%;
		height: 20%;
		padding-left: 10%;
	}
	.imgElement {
		width: 90%;
	}
	.textHeaderM {
		padding-top: 5%;
		padding-bottom: 3%;
		text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
	}
	.mainBottom {
		flex-direction: column;
		width: 90%;
		padding: 5%;
		text-align: center;
	}
	.mainBottomL {
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 0;
		padding-top: 5%;
	}
	.teamImgs {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
	.imgTogether {
		padding-bottom: 5%;
	}
	.buttonsHBot {
		width: 90%;
		padding-top: 0%;
		align-items: center;
		justify-content: space-between;
		padding-left: 5%;
		padding-right: 5%;
	}
	.botButtonTwo button {
		margin: 0;
	}
	.lowerTwo {
		padding: 0;
	}
	.mainBottomR {
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 0;
	}
	.mainBottomR img {
		padding-top: 10%;
		width: 80%;
	}
	.textVozacBaki {
		font-size: 1.5rem;
	}
}
