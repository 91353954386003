*,
html,
body {
	margin: 0;
	padding: 0;
}

button {
	padding: 17px;
	background-color: rgb(237, 100, 54);
	color: #fff;
	border: none;
	border-radius: 25px;
	cursor: pointer;
	font-size: 10px;
	font-family: "Nunito";
	color: rgb(255, 255, 255);
	font-weight: bold;
	text-transform: uppercase;
    width: 170px;
    line-height: 1;
}

button:hover {
	background-color: rgb(212, 89, 48);
}

@media (max-width: 580px) {
	html,
	body {
		overflow-x: hidden;
	}
}


  