.stickyContainer {
  position: fixed;
  top: 40%;
  right: 0;
  transform: translateY(-50%);
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 100;
}

.stickyBox {
  width: 50px;
  height: 55px;
  transition: width 0.3s, background-color 0.3s;
  background-color: rgb(0, 166, 199);
  margin-bottom: 5px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  align-items: center;
  padding-left: 6px;
}

.stickyBox:hover {
  width: 75px;
  background-color: rgb(255, 199, 38);
}

@media (max-width: 950px) {
  .stickyContainer {
    top: 85%;
  }
}
