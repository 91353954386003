/* Footer.css */

.footer {
	bottom: 0;
	left: 0;
	width: 100%;
}

.footerLine {
	width: 100%;
}

.lineTop {
	height: 100px;
	display: flex;
	padding-left: 15%;
	padding-right: 15%;
	box-sizing: border-box;
	color: #fff;
	background-color: rgb(0, 145, 174);
}

.footerTop {
	flex: 1;
	display: flex;
	align-items: center;
	font-family: "Nunito", sans-serif;
}

.footerTop:nth-child(1) {
	justify-content: flex-start;
}

.footerTop:nth-child(2) {
	justify-content: center;
}

.footerTop:nth-child(3) {
	justify-content: flex-end;
}

.lineMid {
	height: 50vh;
	background-image: url("../../img/dogFooter.png");
	background-size: 100% 100%;
	display: flex;
	padding-left: 15%;
	padding-right: 15%;
	box-sizing: border-box;
	color: #fff;
}

.lineMid .midSection {
	width: 33%;
	display: flex;
	align-items: left;
	justify-content: top;
	flex-direction: column;
	font-family: "Nunito", sans-serif;
	padding-right: 5%;
	margin-top: 5%;
}
.leftLogo {
	position: relative;
}

.leftLogo img {
	display: block; /* Make sure the image doesn't have extra spacing underneath */
}
.separatorLine {
	margin-top: 20px;
	margin-bottom: 20px;
	width: 100%;
	height: 4px;
	background-color: rgb(255, 255, 255);
	border-radius: 3px; /* Adjust the border-radius to control the rounded edges */
}

.footerHeader {
	font-size: 1.5rem;
	font-weight: 300;
	font-family: "DynaPuff";
	text-transform: uppercase;
}
.footerLink {
	font-size: 1rem;
	font-weight: 300;
	font-family: "Nunito", sans-serif;
	color: #fff;
	text-decoration: none;
}
.footerLink:hover {
	color: #17766e;
	cursor: pointer;
}

.lineBot {
	height: 30px;
	background-color: #ffd700;
	display: flex;
	padding-left: 15%;
	padding-right: 15%;
	box-sizing: border-box;
	color: #fff;
	font-family: "Nunito", sans-serif;
	font-size: 0.7rem;
}

.lineBot .botSection {
	height: 100%;
	flex: 0 0 8%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color: #554949;
	transition: background-color 0.3s;
}

.lineBot .botSection:first-child {
	justify-content: left;
	flex: 0 0 20%;
}

.lineBot .botSection:last-child {
	margin-left: auto;
	margin-right: 0;
}

.lineBot .botSection.link {
	cursor: pointer;
}
.lineBot .botSection.link:hover {
	background-color: #eaf826;
}
.footerLinkText {
	color: #fff;
	text-decoration: none;
}

@media (max-width: 950px) {
	.lineMid {
		height: 100%;
		background-image: none;
		background-color: rgb(3, 192, 230);
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding-left: 8%;
		padding-right: 8%;
	}
	.midSection img {
		padding-top: 15%;
	}

	.lineMid .midSection {
		padding-bottom: 5%;
		align-items: center;
		width: 100%;
		padding-right: 0;
		margin-top: 0;
	}

	.footerHeader,
	.footerLink {
		text-align: center;
	}

	.lineBot {
		padding: 0;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.lineBot .botSection {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lineBot .link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.footerLine {
		height: 100%;
	}
	.lineTop {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 2%;
		padding-bottom: 2%;
	}
	.footerTop:nth-child(1) {
		justify-content: center;
	}

	.footerTop:nth-child(2) {
		justify-content: center;
	}

	.footerTop:nth-child(3) {
		justify-content: center;
	}

	.footerLink {
		transition: transform 0.3s ease;
	}
	.footerLink:hover {
		transform: scale(1.1);
	}
}
