.kontaktContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	width: 100%;
}
.kontaktHeader {
	width: 100%;
	height: 646px;
	background-image: url("../../img/heroPhoto1.png");
	background-size: cover;
}

.headerText {
	font-size: 3rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(255, 199, 38);
	padding-top: 10%;
	padding-left: 15%;
	text-transform: uppercase;
}

.topContact {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 70%;
	height: 100%;
	padding: 0 15%;
}
.topLeftContact {
	position: relative;
	top: -150px;
	display: flex;
	flex-direction: column;
	width: 65%;
	height: 100%;
	text-align: left;
	z-index: 100;
}
.topRightContact {
	position: relative;
	top: -250px;
	display: flex;
	flex-direction: column;
	width: 30%;
	height: 100%;
}

.topLeftContact .headText {
	font-size: 2rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(0, 166, 199);
	text-transform: uppercase;
	margin: 5% 0%;
}
.topLeftContact .descText {
	font-size: 1rem;
	font-family: "Nunito", sans-serif;
	color: rgb(104, 104, 104);
	margin-bottom: 5%;
}
.topLeftContact .header2 {
	font-size: 1.3rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(0, 166, 199);
	text-transform: uppercase;
	margin: 4% 0%;
}

.contacts {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}
.contactTogether {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	font-family: "Nunito", sans-serif;
	padding: 1% 0%;
	color: rgb(104, 104, 104);
}
.contactTogether img {
	margin-right: 2%;
}

.topLeftContact button {
	margin: 5%;
	align-self: flex-end;
}

.topLeftContact .mapImage {
	width: 100%;
	transition: transform 0.3s ease-in-out;
	cursor: pointer;
}

.topLeftContact .mapImage:hover {
	transform: scale(1.1);
}

.linksText {
	color: rgb(104, 104, 104);
	/* text-decoration: none; */
}

@media (max-width: 1000px) {
	.kontaktHeader {
		height: 400px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.headerText {
		font-size: 2.5rem;
		padding: 0;
		text-shadow: -1px 1px 9px rgba(0, 0, 0, 0.75);
	}
	.topLeftContact .headText {
		font-size: 1.8rem;
	}

	.topContact {
		flex-direction: column;
		padding-left: 5%;
		padding-right: 5%;
		width: 90%;
	}
	.topLeftContact {
		width: 100%;
		text-align: center;
		padding-bottom: 15%;
		top: -100px;
	}

	.topRightContact {
		padding-top: 5%;
		width: 100%;
		top: -100px;
	}
}
