.cenoContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	width: 100%;
}
.cenoHeader {
	width: 100%;
	height: 646px;
	background-image: url("../../img/heroCenovnik.png");
	background-size: cover;
}

.cenoHeaderText {
	font-size: 3rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(255, 199, 38);
	padding-top: 10%;
	padding-left: 15%;
	text-transform: uppercase;
}

.ceneTogether {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 70%;
	height: 100%;
	padding: 0 15%;
}
.ceneLeft {
    position: relative;
    top: -150px;
	display: flex;
	flex-direction: column;
	width: 65%;
	height: 100%;
	text-align: left;
}
.ceneRight {
    position: relative;
    top: -250px;
	display: flex;
	flex-direction: column;
	width: 30%;
	height: 100%;
}
.ceneHeader {
	font-size: 2rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(0, 166, 199);
	text-transform: uppercase;
}

.cenePacks {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}

.packHeader {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	font-size: 1.6rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(255, 255, 255);
	text-transform: uppercase;
	margin: 5% 0%;
	padding-left: 20px;
	width: 350px;
	height: 65px;
	background-image: url("../../img/headerPlate.png");
	background-size: cover;
}
.packText {
	font-size: 1.2rem;
	font-family: "DynaPuff";
	color: rgb(104, 104, 104);
	margin-bottom: 5%;
	padding-left: 20px;
}
.textBlue {
	color: rgb(0, 166, 199);
}
.textGrey {
	color: rgb(150, 150, 150);
}

.packPare {
	display: flex;
	color: rgb(237, 100, 54);
	font-family: "DynaPuff";
	padding-left: 20px;
}

.spanClass {
	font-size: 1.5rem;
	line-height: 1;
}

.superscript {
	font-size: 0.8rem;
	vertical-align: super;
}

.rsdpodanu {
	font-size: 0.8rem;
}

.ceneText {
	font-size: 1rem;
	font-family: "Nunito", sans-serif;
	color: rgb(104, 104, 104);
	padding-left: 20px;
	margin: 4% 0%;
}

.linkSpan span {
	font-size: 1rem;
	font-family: "Nunito", sans-serif;
	color: rgb(0, 166, 199);
	text-decoration: none;
}

@media (max-width: 1000px) {
	.cenoHeader {
		height: 400px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.cenoHeaderText {
		font-size: 2.5rem;
		padding: 0;
		text-shadow: -1px 1px 9px rgba(0, 0, 0, 0.75);
	}
	.ceneHeader {
		font-size: 1.8rem;
		justify-content: center;
	}

	.ceneTogether {
		flex-direction: column;
		padding-left: 5%;
		padding-right: 5%;
		width: 90%;
	}
	.cenePacks {
		flex-direction: column;
		align-items: center; /* Center the packs on smaller screens */
	}

	.packHeader {
		margin: 5% auto; /* Center the header horizontally */
		text-align: center; /* Center the text inside the header */
	}

	.packPare {
		align-items: center; /* Center the .spanClass vertically */
	}

	.ceneLeft {
		width: 100%;
		text-align: center;
        position: relative;
        top: 0px;
	}

	.ceneRight {
		padding-top: 5%;
		width: 100%;
        position: relative;
        top: 0px;
	}
	.spanClass {
		width: 100%;
		align-self: center;
	}
}
