.pbContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 1500px;
  background-image: url("../../img/bgPsi.png");
  background-size: cover;
}

.pbFAQ {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 30%;
  margin-left: 10%;
  margin-top: auto; /* Push pbFAQ to the top */
  margin-bottom: 15%;
}

.questionTogether {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 10%;
  margin: 3% 0%;
  font-family: "Nunito", sans-serif;
  color: rgb(0, 166, 199);
  font-size: 1.2rem;
  font-weight: bold;
}
.questionTogether img {
  cursor: pointer;
}
.pbFAQHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: "DynaPuff";
  color: rgb(0, 166, 199);
  font-size: 2.3rem;
  font-weight: bold;
  text-transform: uppercase;
}

.srceImg {
  width: 100%;
}

.questionAnsw {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: "Nunito", sans-serif;
  color: rgb(104, 104, 104);
  font-size: 1rem;
  margin-top: 2%;
  font-weight: bold;
}

.qnaLink button {
  align-self: center;
  margin-top: 2%;
}

.pbReviews {
  display: flex;
  flex-direction: row;
  width: 64%;
  height: 20%;
  padding-left: 18%;
  padding-right: 18%;
  padding-bottom: 2%;
  justify-content: space-between;
}

.pbRevHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2%;
  font-family: "DynaPuff";
  color: rgb(255, 255, 255);
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: auto;
}

.revBubble {
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 100%;
  background-image: url("../../img/clientBubble.png");
  background-size: 100% 100%; /* Stretch the background */
  background-repeat: no-repeat; /* Prevent background repetition */
  align-items: center;
}

.pbReviews img {
  width: 30%;
  height: 8%;
  margin-top: 5%;
  margin-bottom: 2%;
}

.revText {
  width: 80%;
  height: 75%;
  font-family: "Nunito", sans-serif;
  color: rgb(104, 104, 104);
  font-size: 0.9rem;
  text-align: center;
}

.revName {
  width: 80%;
  height: 7%;
  font-family: "Nunito", sans-serif;
  color: rgb(104, 104, 104);
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
  padding-left: 50%;
  padding-bottom: 10%;
  color: rgb(0, 166, 199);
}

@media (max-width: 950px) {
  .pbRevHeader {
    color: rgb(0, 166, 199);
    padding-bottom: 3%;
  }
  .pbReviews {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
    justify-content: center;
    align-items: center;
  }
  .pbContainer {
    height: 100%;
    padding-top: 30%;
  }
  .pbFAQ {
    width: 80%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 8%;
  }
  .pbFAQHeader {
    font-size: 1.8rem;
    justify-content: center;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }
  .qnaLink {
    padding-top: 5%;
  }
}
