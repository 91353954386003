.qnaContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	width: 100%;
}
.qnaHeader {
	width: 100%;
	height: 646px;
	background-image: url("../../img/qnaHero.png");
	background-size: cover;
}

.qnaHeaderText {
	font-size: 3rem;
	font-weight: bold;
	font-family: "DynaPuff";
	color: rgb(255, 199, 38);
	padding-top: 10%;
	padding-left: 15%;
	text-transform: uppercase;
}

.qnaTogether {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 70%;
	height: 100%;
	padding: 0 15%;
}
.qnaLeft {
	position: relative;
	top: -50px;
	display: flex;
	flex-direction: column;
	width: 65%;
	height: 100%;
	text-align: left;
}
.qnaRight {
	position: relative;
	top: -100px;
	display: flex;
	flex-direction: column;
	width: 30%;
	height: 100%;
}
.faqItem{
    padding-top: 20px;
}

.qnaFAQ {
	display: flex;
	flex-direction: column;
	width: 80%;
	height: 30%;
	margin-bottom: 15%;
}

.QquestionTogether {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 10%;
	margin: 3% 0%;
	font-family: "Nunito", sans-serif;
	color: rgb(0, 166, 199);
	font-size: 1.2rem;
	font-weight: bold;
}
.questionTogether img {
	cursor: pointer;
}
.qnaFAQHeader {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	font-family: "DynaPuff";
	color: rgb(0, 166, 199);
	font-size: 2.1rem;
	font-weight: bold;
	text-transform: uppercase;
}

.srceImg {
	width: 100%;
}

.questionAnsw {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	font-family: "Nunito", sans-serif;
	color: rgb(104, 104, 104);
	font-size: 1rem;
	margin-top: 2%;
	font-weight: bold;
}

.QquestionTogether img {
	cursor: pointer;
}

.botQna {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;
	height: 1100px;
	background-image: url("../../img/faqBot.png");
	background-size: cover;
}

.qnaReviews {
	display: flex;
	flex-direction: row;
	width: 70%;
	height: 30%;
	padding-left: 15%;
	padding-right: 15%;
	padding-bottom: 2%;
	justify-content: space-between;
}
.qnaRevHeader {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 2%;
	font-family: "DynaPuff";
	color: rgb(255, 255, 255);
	font-size: 1.8rem;
	font-weight: bold;
	text-transform: uppercase;
	margin-right: auto;
}

.QrevBubble {
	display: flex;
	flex-direction: column;
	width: 450px;
	height: 100%;
	background-image: url("../../img/clientBubble.png");
	background-size: 100% 100%; /* Stretch the background */
	background-repeat: no-repeat; /* Prevent background repetition */
	align-items: center;
}

.qnaReviews img {
	width: 30%;
	height: 8%;
	margin-top: 5%;
	margin-bottom: 2%;
}
.QrevText {
	width: 80%;
	height: 75%;
	font-family: "Nunito", sans-serif;
	color: rgb(104, 104, 104);
	font-size: 0.9rem;
	text-align: center;
}
.QrevName {
	width: 80%;
	height: 7%;
	font-family: "Nunito", sans-serif;
	color: rgb(104, 104, 104);
	font-size: 1.2rem;
	text-align: center;
	font-weight: bold;
	padding-left: 50%;
	padding-bottom: 10%;
	color: rgb(0, 166, 199);
}

@media (max-width: 1000px) {
	.qnaHeader {
		height: 400px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.qnaHeaderText {
		font-size: 2.5rem;
		padding: 0;
		text-shadow: -1px 1px 9px rgba(0, 0, 0, 0.75);
	}

	.qnaTogether {
		flex-direction: column;
		padding-left: 5%;
		padding-right: 5%;
		width: 90%;
	}
	.qnaFAQHeader {
		font-size: 1.8rem;
		justify-content: center;
	}
	.qnaLeft {
		width: 100%;
		text-align: center;
		padding-bottom: 15%;
		top: -50px;
	}
	.qnaFAQ {
		width: 100%;
	}

	.qnaRight {
		padding-top: 5%;
		width: 100%;
		top: -150px;
	}
	.qnaReviews {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-left: 0%;
		padding-right: 0%;
		justify-content: center;
		align-items: center;
	}
}

@media (min-width: 601px) and (max-width: 750px) {
	.botQna {
		height: 800px;
		padding-bottom: 30%;
	}
}
@media (min-width: 751px) and (max-width: 1000px) {
	.botQna {
		height: 800px;
		padding-bottom: 20%;
	}
}

@media (max-width: 600px) {
	.botQna {
		height: 800px;
		padding-bottom: 40%;
	}
}
