/* DrawerComponent.css */

.drawer-container {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.blue-line {
	height: 10%;
	background-color: rgb(0, 145, 174);
	color: #fff;
	padding-top: 5px;
	padding-bottom: 5px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
}
.imgLeft {
	width: 7%;
	max-width: 20%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
}
.imgRight {
	width: 90%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.imgRight img {
	scale: 0.8;
}

.drawer-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 1;
}

.drawer {
	position: fixed;
	top: 0;
	left: 0;
	width: 80%;
	height: 100%;
	background-color: rgb(0, 166, 199);
	display: flex;
	flex-direction: column;
	z-index: 2;
}
.drawer-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.drawer-logo img {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 10%;
	padding-top: 10%;
}

.drawer-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-decoration: none;
	color: #fff;
	font-family: "DynaPuff";
	transition: background-color 0.5s;
	padding: 10px;
	height: 7%;
}
.drawer-item:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.selected {
	background-color: rgb(0, 145, 174);
}

.selected:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.sticky {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
}
